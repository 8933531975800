import React from 'react';
import {Link} from "react-router-dom";
import "./TopMenu.scss";

function TopMenu() {
    const contactForm = "489a3205-5040-4bbb-8b5e-b2c3e23962dc";

    return (
        <div className="top-menu">
            <Link to={{
                pathname: `/`
            }}>
                HOME
            </Link>
            <Link to={{
                pathname: `/info`
            }}>
                INFO
            </Link>
            {contactForm ? <Link to={{pathname: `/contact`}}>CONTACT</Link> : null}
        </div>
    );
}

export default TopMenu;
